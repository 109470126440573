import NProgress from 'nprogress'
import { getToken } from '@/utils/auth'
import {useUserStore} from "@/store/modules/user"
import { useSettingStore } from '@/store/modules/setting'
import { encrypt } from '@/utils/aes'
const whiteList = ['/','/index','/login','/404','/401','/ai-message','/ai-message/create-chart']

export function createProgressGuard(router) {
  router.beforeEach(async (_to, _from, next) => {
    const {userInfo, getUserInfo, projectStatus} = useUserStore()
    if (!_to.query.lxltsign&&_from.query.lxltsign) {
      _to.query.lxltsign = _from.query.lxltsign
      next({..._to});
    } else {
      if(!useSettingStore().id){
        useSettingStore().getProjectInfo()
      }
      if(!_to.query.lxltsign&&import.meta.env.MODE==="production"&&(_to.path!='/401'&&_to.path!='/404')){
        console.log('12312',_to.path)
        next({ path: '/401' })
      } else if (getToken()){
        if(!userInfo.id){
          await getUserInfo()
        }
        next();
        // if(userInfo.id){
        //   next()
        // }else{
        //   const {error,data} = await getUserInfo()
        //   if(!projectStatus){
        //     next({ path: '/401', query:{..._from.query} })
        //   }else if(!error){
        //     next({..._to});
        //   }
        // }
      } else {
        // 没有token
        if (whiteList.indexOf(_to.path) !== -1) {
          // 在免登录白名单，直接进入
          next()
        } else {
          if(import.meta.env.MODE==="development"){
            next(`/login`)
            NProgress.done()
          }else{
            location.href = import.meta.env.VITE_LOGOUT_PATH + encrypt(window.location.href)
          }
        }
      }
    }
    NProgress.start()

  });
  router.afterEach(_to => {
    NProgress.done()
  });
}
